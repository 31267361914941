.auth-nav-lang {
  min-width: 220px;
  padding: 16px 10px 16px 10px;
}

.auth-nav-lang-list:hover {
  color: #161616;
  background-color: #1cb8941a;
  border-radius: 6px;
  padding: 5px 10px 5px 10px;
}

.auth-nav-lang-list {
  color: #6b6b6b;
  padding: 5px 10px 5px 10px;
}

@media (max-width: 390px) {
  .text-lang {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }
}

@media (min-width: 391px) and (max-width: 834px) {
  .text-lang {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }
}
