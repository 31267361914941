.verificationActionContainer {
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 32px;
}

@media (max-width: 833px) {
  .verificationActionContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.verificationContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.verificationTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
}

.verificationDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 0%;
  text-align: center;
  padding-top: 12px;
}

.verificationBox {
  width: fit-content;
  padding: 24px;
  gap: 16px;
  text-align: start;
  justify-content: center;
  align-items: start;
}

@media (max-width: 833px) {
  .verificationBox {
    padding: 24px 16px;
  }
}

.verificationItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 0%;
  display: flex;
  align-items: center;
}

.verificationItemIcon {
  margin-right: 8px;
}

.verificationItemText {
  text-align: start;
}