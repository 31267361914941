.logo-global {
  width: 80px;
  height: 80px;
}
.logo-dashboard {
  width: 36px;
  height: 36px;
}
.logo-deposit {
  width: 32px;
  height: 32px;
}
.logo-report {
  width: 48px;
  height: 48px;
}
